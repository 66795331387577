import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Image } from "cloudinary-react"
import { toSentenceCase } from "../../utils/utils"
const cloudinaryData = require("../../../config.json")

const cloudinaryImages = cloudinaryData.images.resources

const NuvoImage = props => {
  if (!cloudinaryData) throw new Error("Error retrieving config.json")
  if (!props.publicId) return <></>

  const quality = props.quality ?? 99

  let image = { public_id: props.publicId }
  let alt = ""

  const findData = cloudinaryImages.find(
    data => data.public_id === props.publicId
  )

  if (findData) {
    image = findData
  }

  if (image.context && image.context.custom.alt) {
    alt = image.context.custom.alt
  } else {
    const generateAlt = props.publicId
      .split("LDS/DEV/")[1] //split cloudinary prefix
      .replace(/[_-]/g, " ")
    alt = toSentenceCase(generateAlt)
  }

  const imageWrapperClasses = classNames(props.wrapperClassName, {
    "image-meta__wrapper": true
  })

  const imageClasses = classNames(props.className, {
    "image-meta__image": true
  })

  const { useAR, wrapperClassName, ...rest } = props
  if (props.useAR) {
    return (
      <div
        style={{
          position: "relative",
          height: 0,
          paddingTop: (image.height / image.width) * 100 + "%"
        }}
        className={imageWrapperClasses}>
        <Image
          style={{
            position: "absolute",
            top: 0,
            left: 0
          }}
          quality={quality}
          alt={alt}
          responsive
          {...rest}
          className={imageClasses}>
          {props.children}
        </Image>
      </div>
    )
  } else {
    return (
      <Image
        quality={quality}
        alt={alt}
        responsive
        {...rest}
        className={imageClasses}>
        {props.children}
      </Image>
    )
  }
}

NuvoImage.propTypes = {
  publicId: PropTypes.string.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
  useAR: PropTypes.bool,
  wrapperClassName: PropTypes.string
}

export default NuvoImage

NuvoImage.defaultProps = {
  cloudName: "nuvolum",
  width: "auto"
}
