import React, { Component } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

class GeneralHours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateLines: []
    }
  }

  componentDidMount() {
    this.sortDays(this.props.locationHours)
  }

  sortDays(arr) {
    if (!arr) throw "No array of days to sort, " + arr + " was received."
    const groupBy = (items, key) =>
      items.reduce(
        (result, item) => ({
          ...result,
          [item[key]]: [...(result[item[key]] || []), item]
        }),
        {}
      )

    let newArr = []
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      obj.combinedTime = `${obj.startTime}–${obj.endTime}`
      obj.shortWeekDay = whatShortWeekDay(obj.weekDay, this.props.language)
      obj.fullWeekDAy = whatWeekDay(obj.weekDay, this.props.language)
      newArr.push(obj)
    }

    const sortedDates = groupBy(newArr, "combinedTime")

    for (let i = 0; i < sortedDates.length; i++) {
      sortedDates[i].sort((a, b) => (a.weekDay > b.weekDay ? 1 : -1))
    }

    let dayLineArr = []
    let combinedTimeObjs = []

    for (let key in sortedDates) {
      const obj = sortedDates[key]
      let tempArr = []
      let tempArrFullObj = []
      for (let i = 0; i < obj.length; i++) {
        tempArr.push({
          shortWeekDay: obj[i].shortWeekDay,
          weekDay: obj[i].weekDay
        })
        tempArrFullObj.push(obj[i])
      }
      dayLineArr.push(tempArr)
      combinedTimeObjs.push(tempArrFullObj)
      tempArr = []
      tempArrFullObj = []
    }

    let dayLineDays = []
    let dayLineTimes = []

    function nonConsecutiveDays() {
      if (allDaysAreConsecutive(dayLineArr)) {
        for (let i = 0; i < dayLineArr.length; i++) {
          dayLineDays.push(joinWithDash(dayLineArr[i]))

          let tempDayTimes = combinedTimeObjs[i][0].combinedTime
          dayLineTimes.push(tempDayTimes)
        }
      } else {
        for (let i = 0; i < dayLineArr.length; i++) {
          if (dayLineArr[i].length === 1) {
            dayLineDays.push(dayLineArr[i][0].shortWeekDay + ": ")
          } else if (daysInLineAreConsecutive(dayLineArr[i])) {
            dayLineDays.push(joinWithDash(dayLineArr[i]))
          } else {
            dayLineDays.push(joinWithCommas(dayLineArr[i]))
          }

          let tempDayTimes = combinedTimeObjs[i][0].combinedTime
          dayLineTimes.push(tempDayTimes)
        }
      }

      let finalDateLines = []

      for (let i = 0; i < dayLineDays.length; i++) {
        let obj = {}

        obj.days = dayLineDays[i]
        obj.times = dayLineTimes[i]
        finalDateLines.push(obj)
      }

      return finalDateLines
    }

    // Only one array of days
    function allDaysAreConsecutive(days) {
      if (days.length === 1) {
        return true
      }
      return false
    }

    // Join length of consecutive days with dash
    function joinWithDash(dayLineArr) {
      let tempDayLine =
        dayLineArr[0].shortWeekDay +
        "–" +
        dayLineArr[dayLineArr.length - 1].shortWeekDay
      tempDayLine += ": "
      return tempDayLine
    }

    // Join non-all consecutive days with dash
    function joinWithCommas(dayLineArr) {
      let tempDayLine = []
      for (let j = 0; j < dayLineArr.length; j++) {
        tempDayLine.push(dayLineArr[j].shortWeekDay)
      }
      tempDayLine = tempDayLine.join(", ")
      tempDayLine += ": "
      return tempDayLine
    }

    // Check if every day - 1 is equal to the day before
    function daysInLineAreConsecutive(daysObj) {
      let consecutive = true
      for (let j = 1; j < daysObj.length; j++) {
        if (
          parseInt(daysObj[j - 1].weekDay) + 1 !==
          parseInt(daysObj[j].weekDay)
        ) {
          consecutive = false
        }
      }
      return consecutive
    }

    // One-off for Mon-Fri, needs to be re-worked
    function allConsecutiveDays() {
      let startDay = combinedTimeObjs[0][0]
      let endDay = combinedTimeObjs[0][0]

      for (let i = 0; i < combinedTimeObjs[0].length - 1; i++) {
        if (
          parseInt(combinedTimeObjs[0][i + 1].weekDay) ===
          parseInt(endDay.weekDay) + 1
        ) {
          endDay = combinedTimeObjs[0][i + 1]
        }
      }

      let tempDayLine = startDay.shortWeekDay + "–" + endDay.shortWeekDay + ": "
      let tempDayTimes = combinedTimeObjs[0][0].combinedTime
      dayLineDays.push(tempDayLine)
      dayLineTimes.push(tempDayTimes)
      let finalDateLines = []
      let obj = {}

      obj.days = dayLineDays[0]
      obj.times = dayLineTimes[0]
      finalDateLines.push(obj)

      return finalDateLines
    }

    // allConsecutiveDays();

    let finalDateLines = nonConsecutiveDays()
    // let finalDateLines = allConsecutiveDays()

    function linkConsecutiveDays(days) {}

    this.setState({
      dateLines: finalDateLines
    })

    function whatWeekDay(date, language) {
      let day = date
      if (language && language === "es") {
        switch (day) {
          case "0":
            day = "Domingo"
            return day

          case "1":
            day = "Lunes"
            return day

          case "2":
            day = "Martes"
            return day

          case "3":
            day = "Miércoles"
            return day

          case "4":
            day = "Jueves"
            return day

          case "5":
            day = "Viernes"
            return day

          case "6":
            day = "Sábado"
            return day
        }
      }
      switch (day) {
        case "0":
          day = "Sunday"
          return day

        case "1":
          day = "Monday"
          return day

        case "2":
          day = "Tuesday"
          return day

        case "3":
          day = "Wednesday"
          return day

        case "4":
          day = "Thursday"
          return day

        case "5":
          day = "Friday"
          return day

        case "6":
          day = "Saturday"
          return day
      }
    }

    function whatShortWeekDay(date, language) {
      let day = date
      if (language && language === "es") {
        switch (day) {
          case "0":
            day = "Do"
            return day

          case "1":
            day = "Lu"
            return day

          case "2":
            day = "Ma"
            return day

          case "3":
            day = "Mi"
            return day

          case "4":
            day = "Ju"
            return day

          case "5":
            day = "Vi"
            return day

          case "6":
            day = "Sa"
            return day
        }
      }
      switch (day) {
        case "0":
          day = "Sun"
          return day

        case "1":
          day = "Mon"
          return day

        case "2":
          day = "Tues"
          return day

        case "3":
          day = "Wed"
          return day

        case "4":
          day = "Thur"
          return day

        case "5":
          day = "Fri"
          return day

        case "6":
          day = "Sat"
          return day
      }
    }
  }

  render() {
    let dateLines = this.state.dateLines.map((line, i) => (
      <React.Fragment key={i}>
        {line.times === "–" ? (
          <></>
        ) : (
          <React.Fragment>
            {line.days}
            <span className="no-word-break">{line.times}</span>
            <br />
          </React.Fragment>
        )}
      </React.Fragment>
    ))

    const wrapperClass = classNames(this.props.className, {
      "footer-hours": this.props.footer,
      "contact-sidebar-block": this.props.contact
    })

    const paragraphClassName = classNames({
      small: this.props.footer,
      "contact-hours": this.props.contact
    })

    return (
      <div className={wrapperClass}>
        {this.props.heading && <h6>{this.props.heading}</h6>}
        <p className={paragraphClassName}>{dateLines}</p>
      </div>
    )
  }
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.footer && !props.contact) {
    return new Error(
      `One of 'footer' or 'contact' is required by '${componentName}' component.`
    )
  }
  if (props.footer && typeof props.footer !== "boolean") {
    return new Error(`'footer' is not a boolean`)
  }
  if (props.contact && typeof props.contact !== "boolean") {
    return new Error(`'contact' is not a boolean`)
  }
}

GeneralHours.propTypes = {
  className: PropTypes.string,
  locationHours: PropTypes.array.isRequired,
  language: PropTypes.string
}

export default GeneralHours
