import React from "react"
import PropTypes from "prop-types"
import TheaterVideo from "@components/Theater/TheaterVideo"
import NuvoImage from "../../components/NuvoImage"
import MarkdownViewer from "../../../bit-components/markdown-viewer/dist/MarkdownViewer"

function TopSection(props) {
  const { language, post } = props
  return (
    <>
      <div className="columns top-section white-back">
        <div className="column is-4" />
        <div className="column">
          <h1
            className="mobile-left has-text-centered"
            style={{ marginTop: "0" }}>
            {post.heading}
          </h1>
          <MarkdownViewer markdown={post.topBlurb} />
        </div>
        <div className="column is-4" />
      </div>

      {post.mainVideo.youtube && (
        <div className="columns has-text-centered about-main-theater-image">
          <div className="column is-full-width">
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
              controls
              playing
              overVideo
              language={language}>
              <NuvoImage
                cloudName="nuvolum"
                wrapperClassName="show-desktop-only"
                publicId={post.mainVideo.imageId}
                useAR
                width="auto"
                responsive
              />
              <NuvoImage
                cloudName="nuvolum"
                wrapperClassName="show-desktop-only-inverse"
                publicId={post.mainVideo.imageIdMobile}
                useAR
                width="auto"
                responsive
              />
            </TheaterVideo>
          </div>
        </div>
      )}
    </>
  )
}

TopSection.propTypes = {
  post: PropTypes.object.isRequired,
  language: PropTypes.string
}

export default TopSection
